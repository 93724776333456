import { render, staticRenderFns } from "./mod.vue?vue&type=template&id=0d8f6578&scoped=true&"
import script from "./mod.vue?vue&type=script&lang=js&"
export * from "./mod.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d8f6578",
  null
  
)

export default component.exports