<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col
          cols="12"
          sm="12"
          order="2"
          order-sm="2"
          order-md="1"
          order-lg="1"
          md="7"
          lg="9"
          class="pt-8 pl-8"
        >
          <h4 class="title">Data Modul</h4>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
            <v-dialog v-model="dialog" max-width="700px">
              <v-card>
                <v-card-title>
                  <span>Data Modul</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col  cols="12" sm="6" md="6">
                      <v-text-field label="Nama" v-model="nama" required color="green"></v-text-field>
                    </v-col>
                    <v-col  cols="12" sm="6" md="6">
                      <v-text-field label="Direktori" v-model="direktori" required color="green"></v-text-field>
                    </v-col>
                    <v-col  cols="12" sm="4" md="4">
                      <v-select item-text="nama" item-value="id" :items="lsModulGrup" label="Modul grup" 
                      v-model="mg_id" required></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field label="Urutan" v-model="urutan" required color="green"></v-text-field>
                    </v-col>
                     <v-col cols="12" sm="4" md="4">
                      <v-text-field label="Icon" v-model="gbr" required color="green"></v-text-field>
                    </v-col>
                  </v-row>
                  </v-form>
                </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-btn color="cyan" @click="dialog = false">Cancel</v-btn>
                  <v-btn color="cyan" @click="saveData">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialog2" max-width="700px">
              <v-card>
                <v-card-title>
                  <span>Data Grup</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col  cols="12" sm="6" md="6">
                      <v-text-field label="Nama Grup" v-model="nama" required color="green"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field label="Urutan" v-model="urutan" required color="green"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-btn color="cyan" @click="dialog2 = false">Cancel</v-btn>
                      <v-btn color="cyan" @click="saveData2">Save</v-btn>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-card class="mx-auto" tile>
                        <v-data-table
                          :headers="headers2"
                          :items="lsModulGrup"
                          :disable-pagination="false"
                          :hide-default-footer="false"
                          :footer-props="{
                            'items-per-page-options': [50, 1000]
                          }"
                          :items-per-page="50"
                        >
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-icon small class="mr-2" @click="fnEdit2(item.id)">mdi-pencil </v-icon>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                  </v-row>
                  </v-form>
                </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>

             <v-row align="center" class="list px-0 mx-auto">
              <v-col cols="12" md="6">
                <v-text-field v-model="text_cari" label="cari..." v-on:keyup="inputKeydown">></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                  <v-btn color="cyan" class="mb-0"  @click="fnBaru()" small>+Menu</v-btn>
                  <v-btn color="cyan" class="mb-0"  @click="fnBaru2" small>Group</v-btn>
              </v-col>
              <v-col cols="12" sm="12">
                <v-card class="mx-auto" tile>
                  <v-data-table
                    :headers="headers"
                    :items="ftData"
                    :disable-pagination="false"
                    :hide-default-footer="false"
                    :footer-props="{
                      'items-per-page-options': [50, 1000]
                    }"
                    :items-per-page="50"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="fnEdit(item.id)">mdi-pencil </v-icon>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'

export default {
  data: () => ({
    snackbar: false,
    valid: false,
    nameRules: [
      v => !!v || 'Wajib diisi',
      v => (v && v.length >= 2) || 'Data salah',
    ],
    text: 'OK', text_cari :'', gbr:'',
    timeout: 2000,
    title:'', ta_id2:0, 
    loading: true, nama:'', urutan:0,
    lsData : [], mg_id:0,
    ftData : [], lsModulGrup:[],
    dialog : false, dialog2 : false,
    menuKat : false, st:'baru',
    dialogImpor:false,
    searchTextsLoading: true,
    judul: '',
    kompetensi: '', direktori:'',
    yt_id: '',
    id: 0,
    lokal:'',
    ddMapel : [],
    dtImpor:[],
    files: null,
    mapel:'',
    kelas_id:1,
    guru_id:0,
    ta_id:0,
    lsGuru:[],
    lsKelas:[],
    lsTA:[],
    ddSemester:[{"id":"1","label":"Semester 1"},{"id":"2","label":"Semester 2"}],
    headers: [
      { text: "Group", align: "start", sortable: false, value: "nmGroup" },
      { text: "Modul", value: "nama", sortable: false },
      { text: "Dir", value: "direktori", sortable: false },
      { text: "Icon", value: "gbr", sortable: false },
      { text: "Aksi", value: "actions", sortable: false },
    ],    
    headers2: [
      { text: "Group", align: "start", sortable: false, value: "nama" },
      { text: "Urutan", value: "urutan", sortable: false },
      { text: "Aksi", value: "actions", sortable: false },
    ]
  }),
  components:{
    Navbar
  },
  methods: {
    inputKeydown: function () {
      if(this.text_cari.length>0){
        this.ftData = this.lsData.filter(m => m.nama.toUpperCase().includes(this.text_cari.toUpperCase()))
      }else{
        this.ftData = this.lsData
      }
    },
    validate () {
      this.$refs.form.validate()
    },
    saveData () {
      this.validate()
      if (this.valid===false || this.urutan === '') {
        this.text = 'Data salah atau tidak lengkap'
        this.snackbar =true
      } else {
        const dataku = {
          urutan: this.urutan,
          nama: this.nama,
          direktori: this.direktori,
          mgID: this.mg_id, st:this.st,
          gbr:this.gbr,
          id:this.id
        }
        //console.log('cek dataku ', dataku)
        axios
          .post(api + 'saveAModul', dataku)
          .then(response => {
            //console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              //this.clsForm()
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    saveData2 () {
      this.validate()
      if (this.valid===false || this.urutan === '') {
        this.text = 'Data salah atau tidak lengkap'
        this.snackbar =true
      } else {
        const dataku = {
          urutan: this.urutan,
          nama: this.nama,
          id:this.id
        }
        //console.log('cek dataku ', dataku)
        axios
          .post(api + 'saveAGModul', dataku)
          .then(response => {
            //console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.fnModulGrup()
              //this.dialog2 = false
              //this.fnData()
              //this.clsForm()
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    
    async fnModulGrup() {
      axios
        .get(api + 'lsModulGrup')
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsModulGrup = response.data.data
          }
        })
    },
    async fnData () {
      this.lsData = []
      this.ftData = []
      axios
        .get(api + 'lsAllModul&sk_id=' )
        .then(response => {
         // console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
          }
        })
    },
    async fnBaru() {
      this.clsForm()
      this.st = 'baru'
      this.dialog=true
    },
    async fnBaru2() {
      this.dialog2=true
    },
    async fnEdit(id) {
      const cekIndex = this.lsData.findIndex(cek => cek.id === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = id
      this.mg_id = dtEdit.mgID
      this.nama = dtEdit.nama
      this.direktori = dtEdit.direktori
      this.gbr = dtEdit.gbr
      this.st = 'edit'
      this.dialog = true
    },
    async fnEdit2 (id) {
      const cekIndex = this.lsModulGrup.findIndex(cek => cek.id === id)
      const dtEdit = this.lsModulGrup[cekIndex]
      this.id = id
      this.urutan = dtEdit.urutan
      this.nama = dtEdit.nama
    },
    clsForm () {
      this.lokal = ''
      this.id = 0
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
    this.fnModulGrup()
    this.fnData()
  }
}
</script>

<style lang="scss" scoped></style>
